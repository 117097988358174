import React from 'react'
import useCookieStore from '../store'

interface ToggleProps {
  label?: string
  className?: string
  children?: React.ReactNode
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement>
  onFocus?: React.FocusEventHandler<HTMLButtonElement>
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>
}

const ModalToggle = ({ label, className, children, onMouseOver, onFocus, onMouseLeave }: ToggleProps) => {
  const toggleModalVisibility = useCookieStore(state => state.toggleModalVisibility)
  return (
    <button
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      className={className}
      onClick={toggleModalVisibility}
    >
      {children}
      {label}
    </button>
  )
}

export default ModalToggle
