import React from 'react'
import toast from 'react-hot-toast'
import { Notifications, toastStyles } from './definitions'

export const notifyNotChangeable = (str: string) =>
  toast.error(str, {
    style: toastStyles,
  })
export const notifyToast = (isEnable: boolean, notifications: Notifications, lang: string) => {
  const { all_enabled, all_disabled, refresh } = notifications
  if (isEnable) {
    notifyChanged(all_enabled[lang], true)
  } else {
    toast(
      () => (
        <span>
          {all_disabled[lang]}{' '}
          <button onClick={() => window.location.reload()} className="font-bold">
            {refresh[lang]}
          </button>
        </span>
      ),
      {
        icon: '🚫',
        style: toastStyles,
      }
    )
  }
  return
}

/**
 * Create Toast on category specific consent change
 *
 * @param name string
 * @param value boolean
 */
export const notifyChanged = (str: string | Notifications, enabled: boolean, lang = '') => {
  // If passing Notifications

  const _str: string = typeof str === 'object' ? str.updated[lang] : str

  if (!enabled) {
    toast.success(_str, {
      icon: '🚫',
      style: toastStyles,
    })
  } else {
    toast.success(_str, {
      style: toastStyles,
    })
  }
}
