import React, { MouseEventHandler } from 'react'
import { IoIosClose } from 'react-icons/io'
import { CookieStore } from '../definitions'
import useCookieStore from '../store'

interface HeaderProps {
  onClick: MouseEventHandler
  disableIcon?: boolean
}

const Header = ({ onClick, disableIcon }: HeaderProps) => {
  const currentLang = useCookieStore(state => state.languages.current)
  const title: string = useCookieStore((state: CookieStore) => state.modal.title[currentLang])

  return (
    <header className="flex justify-between items-center pb-2 shadow-xl">
      <p className="text-2xl font-bold pl-4">
        {!disableIcon && '🍪'} {title}
      </p>
      <button onClick={onClick} className="pr-4 grid place-content-center text-primary text-4xl">
        <IoIosClose />
      </button>
    </header>
  )
}

Header.defaultProps = {
  title: 'Cookie Policy',
}

export default Header
