import React from 'react'
import shallow from 'zustand/shallow'
import CookieCategory from '../CookieCategory'
import { CookieCategory as Category, CookieStore } from '../definitions'
import useCookieStore from '../store'

const Categories = () => {
  const categories: Array<Category> = useCookieStore(state => state.categories)
  const currentLang: string = useCookieStore(state => state.languages.current)
  const description: string = useCookieStore((state: CookieStore) => state.modal.description[currentLang])
  const { settings } = useCookieStore(
    state => ({
      settings: state.settings.user_choices,
    }),
    shallow
  )

  return (
    <div className="overflow-y-scroll h-64 p-4">
      {description && <p className="text-xs pb-4">{description}</p>}
      {categories.map((category: Category) => (
        <CookieCategory settings={settings} key={category.name[currentLang]} category={category} />
      ))}
    </div>
  )
}
export default Categories
