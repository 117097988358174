/** ----------------------------------------------------------------------------------------------------
 *  Notification messages
 * ---------------------------------------------------------------------------------------------------*/
export default {
  all_enabled: {
    en: 'All cookies enabled',
    fi: 'Evästeet hyväksytty',
  },
  all_disabled: {
    en: 'All cookies disabled',
    fi: 'Evästeet hylätty',
  },
  refresh: {
    en: 'Click to reload',
    fi: 'Lataa sivu uudelleen',
  },
  updated: {
    en: 'Settings saved',
    fi: 'Asetukset tallennettu',
  },
  not_changeable: {
    en: 'Category not changeable',
    fi: 'Kategoriaa ei voi muuttaa',
  },
}
