import React from 'react'
import { motion } from 'framer-motion'
import Header from './Header'
import Categories from './Categories'
import useCookieStore from '../store'
import { CookieStore } from '../definitions'
import shallow from 'zustand/shallow'

interface ModalProps {
  disableIcon?: boolean
  buttonClasses?: string
  modalTitle?: string
  footerBackground?: string
  isWhiteLabel: boolean
}

const Modal = ({ buttonClasses, modalTitle, footerBackground, isWhiteLabel, disableIcon }: ModalProps) => {
  const enableAll = useCookieStore(state => state.EnableAllCategories)
  const disableAll = useCookieStore(state => state.DisableAllCategories)
  const toggleModalVisibility = useCookieStore(state => state.toggleModalVisibility)
  const closeModal = useCookieStore(state => state.closeModal)

  const { current } = useCookieStore(state => ({ current: state.languages.current }), shallow)
  const enableAllText: string = useCookieStore((state: CookieStore) => state.modal.buttons.footer.enable_all[current])
  const disableAllText: string = useCookieStore((state: CookieStore) => state.modal.buttons.footer.disable_all[current])
  const refreshText: string = useCookieStore((state: CookieStore) => state.modal.buttons.footer.refresh[current])

  return (
    <div className="p-4 fixed w-full h-full grid place-items-center top-0">
      <motion.section
        transition={{ type: 'spring', bounce: 0.5 }}
        initial={{ opacity: 0, top: '20%' }}
        animate={{ opacity: 1, top: 'auto' }}
        exit={{ opacity: 0, top: '20%' }}
        className="bg-white rounded w-full max-w-xl py-4 h-full max-h-96 z-50 relative"
      >
        <Header disableIcon={disableIcon} onClick={toggleModalVisibility} title={modalTitle} />

        {/* All categories */}
        <Categories />

        <footer
          className={`${footerBackground} absolute md:bottom-0 rounded shadow-2xl p-3 flex flex-col md:flex-row gap-3 w-full`}
        >
          <button onClick={enableAll} className={`${buttonClasses}`}>
            {enableAllText}
          </button>
          <button onClick={disableAll} className={`${buttonClasses}`}>
            {disableAllText}
          </button>
          <button onClick={() => window.location.reload()} className={`${buttonClasses}`}>
            {refreshText}
          </button>

          {!isWhiteLabel && (
            <div
              className="flex items-center md:justify-end pr-4 w-1/2 text-xs md:absolute right-0
          h-full top-0"
            >
              <a target="_blank" href="https://g.works" rel="noreferrer">
                Powered by
                <span className="font-bold"> G-Works</span>
              </a>
            </div>
          )}
        </footer>
      </motion.section>

      {/* Backdrop */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={closeModal}
        className="bg-black bg-opacity-90 w-full h-full block fixed z-0"
      ></motion.div>
    </div>
  )
}

Modal.defaultProps = {
  footerBackground: 'bg-white',
  isWhiteLabel: false,
}

export default Modal
