import create from 'zustand'
import { persist } from 'zustand/middleware'
import {
  CookieCategory,
  CookieSettings as Setting,
  CookieStore,
  Script,
  Notifications as Notification,
  CookieName,
  ModalSettings,
} from '../definitions'
import { notifyChanged, notifyToast } from '../Notifications'
import useClearCookies from '../hooks/useClearCookies'
import useSetAcceptCookie from '../hooks/useSetAcceptCookie'
import Events from './Events'
import Modal from './Modal'
import Languages from './Languages'
import Settings from './Settings'
import Notifications from './Notifications'

const cookieStore = (set: (state: unknown) => void, get: () => CookieStore) => ({
  events: Events,
  settings: Settings,
  modal: Modal,
  languages: Languages,
  categories: [],
  scripts: [],
  notifications: Notifications,
  /** -------------------------
   *
   *  ACTIONS
   *
   * -------------------------*/
  setModalVisible: (value: boolean) => set((state: CookieStore) => (state.modal.visible = value)),
  setCurrentLang: (value: string) => set((state: CookieStore) => (state.languages.current = value)),
  toggleModalVisibility: () => set((state: CookieStore) => (state.modal.visible = !state.modal.visible)),
  closeModal: () => set((state: CookieStore) => (state.modal.visible = false)),
  setAnswerGiven: (value: boolean) => set((state: CookieStore) => (state.events.answer_given = value)),
  setExpirationTime: (time: number) => set((state: CookieStore) => (state.settings.expiration_time = time)),
  setCategories: (categories: Array<CookieCategory>) => set((state: CookieStore) => (state.categories = categories)),
  setScripts: (scripts: Array<Script>) => set((state: CookieStore) => (state.scripts = scripts)),
  setNotifications: (notifications: Notification) => set((state: CookieStore) => (state.notifications = notifications)),
  setModalSettings: (modal_settings: ModalSettings) => set((state: CookieStore) => (state.modal = modal_settings)),
  setCookieName: (cookieName: CookieName) => set((state: CookieStore) => (state.settings.cookie_name = cookieName)),
  setDefaultUserChoices: (user_choises: Array<Setting>) =>
    set((state: CookieStore) => (state.settings.user_choices = user_choises)),
  modifySettings: (setting: Setting) => {
    set((state: CookieStore) => {
      state.settings.user_choices = state.settings.user_choices.map((item: Setting) => {
        if (item.id === setting.id) {
          if (!setting.enabled) {
            notifyToast(setting.enabled, get().notifications, get().languages.current)
          } else {
            notifyChanged(get().notifications, setting.enabled, get().languages.current)
          }
          item.enabled = setting.enabled
        }
        return item
      })
    })
  },
  EnableAllCategories: () => {
    set((state: CookieStore) => {
      state.settings.user_choices = state.settings.user_choices.map((item: Setting) => {
        item.enabled = true
        return item
      })
      state.events.answer_given = true
      state.modal.visible = false
    })
    notifyToast(true, get().notifications, get().languages.current)
    useSetAcceptCookie(get().settings.cookie_name, get().settings.expiration_time)
  },
  DisableAllCategories: () => {
    // Get all ids of categories that should not be changed
    const notChangeable = get().categories.filter((category: CookieCategory) => !category.isChangeable)

    set((state: CookieStore) => {
      // Loop through all settings and change them to false
      state.settings.user_choices = state.settings.user_choices.map((item: Setting) => {
        // Check if current item exists in notChangeable
        notChangeable.filter((e: CookieCategory) => e.id === item.id).length === 0 && (item.enabled = false)
        return item
      })
      state.events.answer_given = true
      state.modal.visible = false
    })
    notifyToast(false, get().notifications, get().languages.current)
    useClearCookies(get().settings.expiration_time)
  },
})

// const store = devtools(cookieStore, { name: 'CookieStore' })
const store = cookieStore
const persistedStore = persist(store, {
  name: 'persisted-cookie-storage',
  partialize: state => ({ settings: state.settings }),
})

const useCookieStore = create(persistedStore)

export default useCookieStore
