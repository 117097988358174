/** ----------------------------------------------------------------------------------------------------
 *  Modal settings.
 *      - Visible is used to determine if modal should be visible or not
 *      - Description contains header/hero text in different languages
 * ---------------------------------------------------------------------------------------------------*/
export default {
  visible: false,
  title: {
    en: 'Cookie Consent',
    fi: 'Evästeet',
  },
  description: {
    en:
      'The cookies used on the website gather personal data. Cookies are small files stored in the browser. Without cookies, it’s not possible to separate one user from another. Use this view to accept or reject different types of cookies separately. You may at any time cancel your approval and in any conditions the approval will not be valid for more than 30 days.',
    fi:
      'The cookies used on the website gather personal data. Cookies are small files stored in the browser. Without cookies, it’s not possible to separate one user from another. Use this view to accept or reject different types of cookies separately. You may at any time cancel your approval and in any conditions the approval will not be valid for more than 30 days.',
  },
  buttons: {
    ui: {
      isRounded: false
    },
    yes: {
      en: 'Yes',
      fi: 'Kyllä',
    },
    no: {
      en: 'No',
      fi: 'Ei',
    },
    footer: {
      enable_all: {
        en: 'Enable all',
        fi: 'Hyväksy kaikki',
      },
      disable_all: {
        en: 'Disable all',
        fi: 'Hylkää kaikki',
      },
      refresh: {
        en: 'Refresh',
        fi: 'Päivitä',
      },
    },
  },
}
