import React, { useEffect } from 'react'
import { CookieConsentProps, CookieStore, toastSettings } from './definitions'

import Scripts from './Scripts'
import Modal from './Modal' // Button to toggle modal visiblity
import { AnimatePresence, motion } from 'framer-motion' // Animations
import toast, { Toaster, useToasterStore } from 'react-hot-toast'
import ModalToggle from './ModalToggle' // Modal component
import FloatingButton from './FloatingButton/FloatingButton' // Floating button component
import { default as Consent, Cookies } from 'react-cookie-consent' // Banner component
import Notifications from './store/Notifications'
import useCookieStore from './store'
import { default as ModalSettings } from './store/Modal' // Button to toggle modal visiblity

const CookieConsent = ({
  currentLang,
  expirationTime,
  buttonClasses,
  modalButtonClasses,
  bannerContent,
  settingsLabel,
  acceptButtonText,
  declineButtonText,
  disableIcon,
  categories,
  scripts,
  notifications,
  modalSettings,
  defaultUserChoices,
  cookie,
}: CookieConsentProps) => {
  /**
   * State
   */
  const answerGiven = useCookieStore(state => state.events.answer_given)
  const stateCookie = useCookieStore(state => state.settings.cookie_name)
  const cookieName = cookie || stateCookie
  const modalVisible = useCookieStore(state => state.modal.visible)
  const currentUserChoices = useCookieStore(state => state.settings.user_choices)
  const userChoices = currentUserChoices?.length ? currentUserChoices : defaultUserChoices

  /**
   * Actions
   */
  const setAnswerGiven = useCookieStore(state => state.setAnswerGiven)
  const setCookieName = useCookieStore(state => state.setCookieName)
  const setCurrentLang = useCookieStore(state => state.setCurrentLang)
  const enableAll = useCookieStore(state => state.EnableAllCategories)
  const disableAll = useCookieStore(state => state.DisableAllCategories)
  const setExpirationTime = useCookieStore(state => state.setExpirationTime)
  const setCategories = useCookieStore(state => state.setCategories)
  const setScripts = useCookieStore(state => state.setScripts)
  const setNotifications = useCookieStore(state => state.setNotifications)
  const setModalSettings = useCookieStore(state => state.setModalSettings)
  const setDefaultUserChoices = useCookieStore(state => state.setDefaultUserChoices)

  /**
   * On component mount
   */
  useEffect(() => {
    setAnswerGiven(typeof Cookies.get(cookieName) != 'undefined') // Answer is given if cookie exists
    setExpirationTime(expirationTime) // Set custom expiration time
    setCategories(categories) // Set categories
    setScripts(scripts) // Set scripts
    setNotifications(notifications) // Set notifications
    setModalSettings(modalSettings) // Set notifications
    setCookieName(cookieName)
    setDefaultUserChoices(userChoices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentLang(currentLang)
  }, [currentLang, setCurrentLang])

  /**
   * Notification system
   */
  const { toasts } = useToasterStore()
  useEffect(() => {
    toasts
      .filter(t => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= toastSettings.maximumAmount) // Is toast index over limit?
      .forEach(t => toast.dismiss(t.id)) // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts])

  /**
   * Additional classes
   */

  const rounded = useCookieStore((state: CookieStore) => state.modal.buttons.ui.isRounded)
  modalButtonClasses = rounded ? modalButtonClasses?.replace('rounded', 'rounded-full') : modalButtonClasses
  buttonClasses = rounded ? buttonClasses?.replace('rounded', 'rounded-full') : buttonClasses

  return (
    <div style={{ zIndex: '999' }} className="flex justify-center bg-primary fixed bottom-0 w-full">
      {!modalVisible && (
        <>
          <motion.div
            transition={{ type: 'spring', bounce: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="w-full z-50 grid place-items-center relative bg-primary"
          >
            <Consent
              onAccept={enableAll}
              onDecline={disableAll}
              cookieName={cookieName}
              disableStyles={true}
              enableDeclineButton={true}
              flipButtons={true}
              expires={expirationTime}
              location="bottom"
              containerClasses={'bg-primary max-w-4xl flex flex-col md:flex-row md:items-center py-8 px-2 gap-4 z-50'}
              buttonWrapperClasses={'right-0 flex flex-row gap-2 md:justify-end'}
              contentClasses="text-sm"
              buttonText={acceptButtonText}
              declineButtonText={declineButtonText}
              buttonClasses={buttonClasses}
              declineButtonClasses={buttonClasses}
            >
              {bannerContent + ' '}
              <ModalToggle className="font-bold hover:underline" label={`${settingsLabel}.`} />
            </Consent>
          </motion.div>
          {answerGiven && <FloatingButton />}
        </>
      )}

      <Toaster />
      <AnimatePresence>
        {modalVisible && <Modal disableIcon={disableIcon} buttonClasses={modalButtonClasses} />}
      </AnimatePresence>

      <Scripts />
    </div>
  )
}

CookieConsent.defaultProps = {
  buttonClasses:
    'rounded ease-in duration-100 box-border px-4 py-2 bg-secondary hover:bg-primary border-secondary border-2 text-xs text-white hover:text-secondary',
  modalButtonClasses:
    'rounded ease-in duration-100 box-border px-4 py-2 bg-primary border-2 text-white border-primary text-xs',
  bannerContent:
    'This website uses cookies to gather information on its visitors. You can find out more about which cookies we are using or switch them off in the',
  settingsLabel: 'settings',
  acceptButtonText: 'Accept',
  declineButtonText: 'Decline',
  notifications: Notifications,
  modalSettings: ModalSettings,
}

export default CookieConsent
