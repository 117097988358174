import React from 'react'
import { CookieCategory as Category, CookieSettings as Setting, CookieStore } from '../definitions'
import { notifyNotChangeable } from '../Notifications'
import useCookieStore from '../store'

interface Props {
  category: Category
  settings: Array<Setting>
  buttonClasses?: string
}

const activeClasses = 'bg-black text-white'

const CookieCategory = ({ category, settings, buttonClasses }: Props) => {
  const updateCategory = useCookieStore(state => state.modifySettings)

  const ID = category.id // Current category ID
  const isEnabled = settings.filter((setting: Setting) => setting.id === ID)[0].enabled // Filter out elements without same ID & check if is enabled

  const enableCategory = () => updateCategory({ id: ID, enabled: true })
  const disableCategory = () => updateCategory({ id: ID, enabled: false })

  const currentLang: string = useCookieStore(state => state.languages.current)

  const yesButtonText = useCookieStore((state: CookieStore) => state.modal.buttons.yes[currentLang])
  const noButtonText = useCookieStore((state: CookieStore) => state.modal.buttons.no[currentLang])

  const isChangeable = category.isChangeable
  const notChangeableText = useCookieStore((state: CookieStore) => state.notifications.not_changeable[currentLang])

  const buttonsRounded = useCookieStore((state: CookieStore) => state.modal.buttons.ui.isRounded)
  buttonClasses = buttonsRounded ? buttonClasses?.replace('rounded', 'rounded-full') : buttonClasses

  return (
    <div className="mb-4">
      <label className="text-sm">{category.name[currentLang]}</label>
      <div className="flex flex-col md:flex-row">
        <div className="gw_cookie_category text-xs" dangerouslySetInnerHTML={{ __html: category.description[currentLang] }} />
        <div className={`flex gap-2 text-xs mt-2 md:mt-0 md:ml-4 ${!isChangeable ? 'opacity-50' : ''}`}>
          <button
            onClick={isChangeable ? enableCategory : () => notifyNotChangeable(notChangeableText)}
            className={`${isEnabled && activeClasses} ${buttonClasses}`}
          >
            {yesButtonText}
          </button>
          <button
            onClick={isChangeable ? disableCategory : () => notifyNotChangeable(notChangeableText)}
            className={`${!isEnabled && activeClasses} ${buttonClasses}`}
          >
            {noButtonText}
          </button>
        </div>
      </div>
    </div>
  )
}

CookieCategory.defaultProps = {
  buttonClasses:
    'rounded border-2 border-black box-content px-2 w-12 h-8 hover:bg-black hover:text-white ease-in duration-100',
}

export default CookieCategory
