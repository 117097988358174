import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { IoMdSettings } from 'react-icons/io'
import ModalToggle from '../ModalToggle'

interface FloatingButtonProps {
  label?: string
  toggleClassNames?: string
  containerClassNames?: string
}

const FloatingButton = ({ label, toggleClassNames, containerClassNames }: FloatingButtonProps) => {
  const [textVisible, setTextVisible] = useState(false)

  return (
    <div className={containerClassNames}>
      <AnimatePresence>
        <ModalToggle
          onFocus={() => setTextVisible(true)}
          onMouseOver={() => setTextVisible(true)}
          onMouseLeave={() => setTextVisible(false)}
          className={toggleClassNames}
        >
          <IoMdSettings />
        </ModalToggle>

        {textVisible && (
          <motion.span
            key="span_container"
            transition={{ type: 'spring', duration: 0.2, bounce: 0 }}
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: 'auto' }}
            exit={{ opacity: 0, width: 0 }}
            className="text-xs text-white bg-primary block"
          >
            <motion.span
              key="span_content"
              transition={{ delay: 0.2 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="mr-7"
            >
              {label}
            </motion.span>
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  )
}

FloatingButton.defaultProps = {
  label: 'Settings',
  toggleClassNames: 'p-4 grid place-items-center bg-primary text-white',
  containerClassNames: 'fixed left-0 bottom-0 flex m-2 items-center border-2 border-primary rounded bg-primary',
}

export default FloatingButton
