import React from 'react'
import { Helmet } from 'react-helmet'
import { CookieSettings, Script } from '../definitions'
import useCookieStore from '../store'

export default () => {
  const settings = useCookieStore(state => state.settings.user_choices)
  const scripts = useCookieStore(state => state.scripts)

  return (
    <>
      {scripts &&
        settings &&
        scripts.map((script: Script) => {
          const enabled = settings.filter(
            (category: CookieSettings) => category.enabled === true && script.cookieCategory == category.id
          )

          return (
            enabled.length !== 0 && (
              <Helmet
                key={script.name}
                script={[
                  {
                    type: 'text/javascript',
                    innerHTML: script.script,
                  },
                ]}
              />
            )
          )
        })}
    </>
  )
}
