import { Cookies } from 'react-cookie-consent'
export default (expirationTime: number) => {
  Object.keys(Cookies.get()).forEach(cookieName => {
    const neededAttributes = {
      expires: expirationTime,
    }
    Cookies.remove(cookieName, neededAttributes)
  })
  return expirationTime
}
